@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-size: 16px;
  --spacing-xxs: 0.25rem;
  --spacing-xs: 0.5rem;
  --spacing-sm: 1rem;
  --spacing-md: 1.5rem;
  --spacing-lg: 2rem;
  --spacing-xl: 2.5rem;
  --spacing-xxl: 3rem;
  --font-family-ibm-plex-sans: 'IBM Plex Sans', sans-serif;
  --font-family-ibm-plex-mono: 'IBM Plex Mono', monospace;
  --font-page-heading: 700 2rem / 2.5rem var(--font-family-ibm-plex-sans);
  --font-section-heading: 400 1.75rem / 2.25rem var(--font-family-ibm-plex-sans);
  --font-subhed: 500 1.25rem / 1.5rem var(--font-family-ibm-plex-sans);
  --font-smaller-heading: 400 1.125rem / 1.5rem var(--font-family-ibm-plex-sans);
  --font-body: 400 1rem / 1.5rem var(--font-family-ibm-plex-sans);
  --font-statements: 400 1rem / 1.25rem var(--font-family-ibm-plex-sans);
  --font-button: 700 1rem / 1.5rem var(--font-family-ibm-plex-sans);
  --font-asset-title: 500 1rem / 1.25rem var(--font-family-ibm-plex-sans);
  --font-small-statements: 400 0.875rem / 1rem var(--font-family-ibm-plex-sans);
  --font-label: 700 0.875rem / 1rem var(--font-family-ibm-plex-sans);
  --font-tag: 400 0.875rem / 1rem var(--font-family-ibm-plex-sans);
  --font-smaller-tag: 400 0.75rem / 1rem var(--font-family-ibm-plex-sans);
  --font-code: 400 0.875rem / 1rem var(--font-family-ibm-plex-mono);
  --color-black: #000000;
  --color-black-10: #202223;
  --color-blue-10: #1b1e2e;
  --color-blue-20: #222b5c;
  --color-blue-30: #2a388a;
  --color-blue-40: #3246b9;
  --color-blue-50: #3d5afe;
  --color-blue-60: #778bfe;
  --color-blue-70: #c5cdff;
  --color-blue-80: #ecefff;
  --color-blue-90: #3349c4;
  --color-blue-100: #3953e7;
  --color-blue-500:#3b82f6;
  --color-gray-1: #171717;
  --color-gray-2: #282828;
  --color-gray-3: #565656;
  --color-gray-4: #8b8b8b;
  --color-gray-5: #dcdcdc;
  --color-gray-6: #f3f3f3;
  --color-gray-7: #8B8B8B;
  --color-gray-8: #DCDCDC;
  
  --color-green-10: #1c3423;
  --color-green-20: #1f4229;
  --color-green-30: #245f34;
  --color-green-40: #297c40;
  --color-green-50: #31a752;
  --color-green-60: #77cf90;
  --color-green-70: #def1e3;
  --color-green-80: #ecf8ef;
  --color-green-90: #d9f2e2;
  --color-orange-10: #453318;
  --color-orange-20: #5d4118;
  --color-orange-30: #8b5d19;
  --color-orange-40: #b9791a;
  --color-orange-50: #ffa31b;
  --color-orange-60: #ffbf5f;
  --color-orange-70: #ffdaa4;
  --color-orange-80: #fff6e8;
  --color-purple-10: #2e1f45;
  --color-purple-20: #3a235c;
  --color-purple-30: #512b89;
  --color-purple-40: #6833b7;
  --color-purple-50: #8a3ffc;
  --color-purple-60: #ad79fd;
  --color-purple-70: #d0b2fe;
  --color-purple-80: #f3ecff;
  --color-red-10: #2b1819;
  --color-red-20: #52191c;
  --color-red-30: #791a1f;
  --color-red-40: #a01c23;
  --color-red-40-on-black: #e2525a;
  --color-red-50: #da1e28;
  --color-red-60: #e56168;
  --color-red-70: #f0a5a9;
  --color-red-80: #fbe8e9;
  --color-scrim: rgba(23, 23, 23, 0.5);
  --color-white: #ffffff;
  --color-yellow-10: #453c1e;
  --color-yellow-20: #5c4f22;
  --color-yellow-30: #8a7428;
  --color-yellow-40: #b8992f;
  --color-yellow-50: #fdd13a;
  --color-yellow-60: #fedf75;
  --color-yellow-70: #feedb0;
  --color-yellow-80: #fffaeb;
  --color-yellow-90: #ffeca8;
}

html,
body {
  font-size: var(--font-size);
}

body {
  font-family: var(--font-family-ibm-plex-sans);
}

.apexcharts-legend-series {
  cursor: pointer !important;
}

.apexcharts-menu-item {
  background-color: #0a0b0a;
  color: white;
  font-size: 14px;
  border: 2px solid #0c0c0c;
}

.apexcharts-menu-item:hover {

  color: black;
  border: '0px solid #0c0c0c' !important;
}

.apexcharts-menu-item-open:hover {
  background-color: #45a049;
  border-color: #2e7031;
  border: '0px transparent' !important;
  border-radius: 0px !important;
  padding: 0px !important;
}

.apexcharts-menu-open {
  border: '0px solid black !important;';
  border-radius: 0px !important;
  padding: 0px !important;
}

.apexcharts-menu {
  background: #000000 !important;
  border-radius: 0px !important;
  border-color: transparent !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: '0px transparent' !important;
  border-radius: 0px !important;
  padding: 0px !important;
}
/* .react-datepicker__month-container{
  height: '313.5px' !important;
} */

@media (prefers-color-scheme: light) {
  .apexcharts-menu-item {
    background-color: white;
    color: rgb(15, 14, 14);
    font-size: 14px;
    border: 2px solid white;
  }
    .react-datepicker{
border:1px solid var(--color-gray-5) !important;
    }

  .apexcharts-menu-item:hover {

    color: black;
    border: '0px solid #0c0c0c' !important;
  }

  .apexcharts-menu-item-open:hover {
    background-color: #45a049;
    border-color: #2e7031;
    border: '0px transparent' !important;
    border-radius: 0px !important;
    padding: 0px !important;
  }

  .apexcharts-menu-open {
    border: '0px solid black !important;';
    border-radius: 0px !important;
    padding: 0px !important;
  }

  .react-datepicker__month-container {
    background-color: rgb(255, 255, 255) !important;
    color: rgb(15, 14, 14) !important;
    border: 2px solid rgb(254, 254, 254) !important;

  }

  .react-datepicker__header {
    background-color: rgb(255, 255, 255) !important;
    color: rgb(15, 14, 14) !important;
    /* margin-left: 1px !important; */
  }

  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    cursor: default;
    color: var(--color-gray-4) !important;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    border-radius: 0.3rem;
    background-color: #216ba5;
    color: rgb(251, 249, 249) !important;
  }


  .apexcharts-text .apexcharts-yaxis-label {
    color: white !important;
  }

  .apexcharts-toolbar {
    /* top: -16% !important; */
    right: 10px !important;
    border: 1px solid var(--color-gray-3, #565656) !important;
    padding: 8px !important;
  }

  .apexcharts-xcrosshairs {
    stroke: var(--Gray-Gray-4, #8b8b8b) !important;
  }

  .apexcharts-title-text {
    color: var(--color-white, #ffffff);

    font-family: "IBM Plex Sans" !important;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}

@media (prefers-color-scheme: dark) {
  html {
    background-color: var(--color-gray-1);
  }
  .react-datepicker__month-container {
    background-color: rgb(31 41 55) !important;
    color: black !important;

    /* border: 2px solid rgb(31 41 55) !important; */

  }

  .react-datepicker__aria-live {
    color: rgb(31 41 55) !important;
  }

  .react-datepicker {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: var(--color-gray-4) !important;
    border: 1px solid var(--color-gray-3) !important;

    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
    line-height: initial;
  }

  .react-datepicker__header {
    background-color: rgb(31 41 55) !important;
    color: var(--color-gray-5) !important;
    border-bottom: 1px solid var(--color-gray-3) !important;
  }

  .react-datepicker__day-name {
    color: var(--color-gray-5) !important;
  }

  /* .react-datepicker__month .react-datepicker__month-text{
    color: var(--color-gray-5) !important;
  } */

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    border-radius: 0rem !important;
    background-color: #216ba5;
    color: rgb(17, 17, 17) !important;
  }

  .react-datepicker__day,
  .react-datepicker__time-name {
    color: var(--color-gray-5) !important;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
  }


  .react-datepicker__month-text--disabled{
    cursor: default;
    color: var(--color-gray-3) !important;
  }



  .apexcharts-toolbar {
    /* top: -4% !important; */
    right: 10px !important;
    border: 1px solid var(--Gray-Gray-3, #DCDCDC) !important;
    padding: 8px !important;
  }

  .apexcharts-yaxis-label {
    color: white;
  }

  .apexcharts-title-text {
    color: var(--Gray-Gray-1, #171717);

    font-family: "IBM Plex Sans" !important;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .apexcharts-legend-text {
    color: var(--Gray-Gray-1, #171717);
    font-family: "IBM Plex Sans" !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}
.react-datepicker__month-container {
  height: 292.5px !important;
  min-height: 'fit-content' !important;
  
}
.react-datepicker__month-wrapper{
  padding: 13px 0px !important;
}
.react-datepicker__month-text {
  margin:  0px 5px !important ;
padding: 5px;
}